import React from 'react'

import { Container } from './PrintController.styled'

export const PrintController = ({
  children,
  showOnPrint = true,
  showOnScreen = true,
}) => (
  <Container showOnScreen={showOnScreen} showOnPrint={showOnPrint}>
    {children}
  </Container>
)

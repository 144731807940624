import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'
import { injectGlobal } from 'emotion'
import { fontFace } from 'polished'
import { get } from 'lodash'

import { DevRefreshButton } from 'src/components/Layout/DevRefreshButton/DevRefreshButton'
import { Footer } from 'src/components/Layout/Footer/Footer'
import { HeaderNav } from 'src/components/Layout/HeaderNav/HeaderNav'
import { PrintController } from 'src/components/Shared/PrintController/PrintController'
import { Container, Content } from './Layout.styled'

import 'typeface-ibm-plex-mono'
import 'typeface-rubik'
import './Layout.css'

//TODO, move this variable somewhere (kalapawai.netlify.com)
injectGlobal`
  ${fontFace({
    fontFamily: 'Quickbrush',
    fontFilePath: withPrefix('/quickbrush'),
    fontWeight: '400',
  })}
`

export const Layout = ({
  children,
  activePageName,
  resetMenu,
  showHeaderFooterOnPrint = true,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        prismicSettings {
          data {
            site_title
            site_description
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          defaultTitle={get(data, 'prismicSettings.data.site_title')}
          titleTemplate={`%s – ${get(data, 'prismicSettings.data.site_title')}`}
          meta={[
            {
              name: 'description',
              content: get(data, 'prismicSettings.data.site_description'),
              lang: 'en',
            },
          ]}
        />
        <ParallaxProvider>
          <Container>
            {process.env.NODE_ENV === 'development' && <DevRefreshButton />}
            <PrintController showOnPrint={showHeaderFooterOnPrint}>
              <HeaderNav
                activePageName={activePageName}
                resetMenu={resetMenu}
              />
            </PrintController>
            <Content>{children}</Content>
            <PrintController showOnPrint={showHeaderFooterOnPrint}>
              <Footer dontRenderCat={true} />
            </PrintController>
          </Container>
        </ParallaxProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
